import React, { Component } from "react";
import Loader from "../components/Loader/Loader";
import ReactTable from "react-table";
import ReactDatetime from "react-datetime";
import LoadingOverlay from 'react-loading-overlay';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Label,
    Row,
    Col,
} from "reactstrap";

// Utility function to get formatted current date
const getCurrentFormattedDate = () => {
    const formatter = new Intl.DateTimeFormat();
    const parts = formatter.formatToParts().reduce((acc, part) => {
        acc[part.type] = part.value.padStart(2, '0');
        return acc;
    }, {});
    return `${parts.day}-${parts.month}-${parts.year}`;
};

class CustomerServiceKPI extends Component {
    constructor(props) {
        super(props);        
        this.state = {
            company: props.company,
            toDate: getCurrentFormattedDate(),
            fromDate: getCurrentFormattedDate(),
            loadingBookings: false,
            bookingsSubArray: [],
            bookingsArray: [],
            reklamasjon: 0,
            tilbud: 0,
            tilbudAkseptert: 0,
            selectedCompany: 0,
            bookings: "",
            complaints: "",
            openedCollapses: [],
            spinnerActive: false,
            selectedUserName: "",
            expanded: {},
        };
    }

    componentDidMount() {
        this.fetchBookings();
    }

    fetchBookings = () => {
        const { fromDate, toDate, company } = this.state;
        const url = `${global.config.server.connection.url}/api/Reports/GetBookingsPrCompany?fromDate=${fromDate}&toDate=${toDate}&companyName=${company}`;

        this.setState({ spinnerActive: true });

        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.access_token.replace(/['"]+/g, '')}`
            },
        })
            .then(res => res.json())
            .then(result => {
                this.setState({
                    loadingBookings: false,
                    spinnerActive: false,
                    bookingsArray: result.map((prop, key) => ({
                        id: key,
                        companyName: prop.companyName,
                        numberOfBookings: prop.numberOfBookings,
                    }))
                });
            })
            .catch(error => {
                console.error('Error fetching bookings:', error);
                this.setState({ loadingBookings: false, spinnerActive: false });
            });
    }

    changeFromDate = event => {
        this.setState({ fromDate: event.format("DD-MM-YYYY") });
    }

    changeToDate = event => {
        this.setState({ toDate: event.format("DD-MM-YYYY") });
    }

    refreshClick = () => {
        this.fetchBookings();
    }

    handleRowClick = (rowInfo) => {
        if (this.state.rowexpanded === rowInfo.viewIndex) {
            this.setState({ expanded: { [rowInfo.viewIndex]: false }, rowexpanded: null });
        } else {
            const { fromDate, toDate, company } = this.state;
            const url = `${global.config.server.connection.url}/api/Reports/GetBookingsPrCompanyDetails?fromDate=${fromDate}&toDate=${toDate}&selectedCompany=${rowInfo.original.companyName}&companyName=${company}`;

            this.setState({ spinnerActive: true });

            fetch(url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.access_token.replace(/['"]+/g, '')}`
                },
            })
                .then(res => res.json())
                .then(result => {
                    console.log(result);
                    this.setState({
                        spinnerActive: false,
                        bookingsSubArray: result.map((prop, key) => ({
                            id: key,
                            jobId: prop.jobId,
                            companyName: prop.companyName,
                            source: prop.source,
                            jobType: prop.jobType,
                            date: prop.date,
                            time: prop.time,
                        }))
                    });
                });

            this.setState({
                selectedUserName: rowInfo.row.employeeName,
                expanded: { [rowInfo.viewIndex]: true },
                rowexpanded: rowInfo.viewIndex,
            });
        }
    }

    render() {
        const { loadingBookings, spinnerActive, bookingsArray, bookingsSubArray, fromDate, toDate } = this.state;

        if (loadingBookings) {
            return <Loader />;
        }

        return (
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Bookinger pr. selskap</CardTitle>
                                <Row>
                                    <Col md="2">
                                        <Label>Fra dato:</Label>
                                        <ReactDatetime
                                            value={fromDate}
                                            closeOnSelect={true}
                                            dateFormat="DD-MM-YY"
                                            timeFormat={false}
                                            onChange={this.changeFromDate}
                                            inputProps={{ className: "form-control", placeholder: "Velg fra-dato" }}
                                        />
                                    </Col>
                                    <Col md="2">
                                        <Label>Til dato:</Label>
                                        <ReactDatetime
                                            value={toDate}
                                            closeOnSelect={true}
                                            dateFormat="DD-MM-YY"
                                            timeFormat={false}
                                            onChange={this.changeToDate}
                                            inputProps={{ className: "form-control", placeholder: "Velg til-dato" }}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 10 }}>
                                    <Col md="12">
                                        <Button className="btn-round" color="success" onClick={this.refreshClick}>
                                            Oppdater
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <LoadingOverlay
                                active={spinnerActive}
                                spinner
                                styles={{ spinner: base => ({ ...base, width: '150px', '& svg circle': { stroke: 'rgba(107, 208, 152, 1)' } }) }}
                                text="Loading your content..."
                            >
                                <CardBody>
                                    <ReactTable
                                        expanded={this.state.expanded}
                                        data={bookingsArray}
                                        defaultPageSize={5}
                                        showPaginationTop
                                        showPaginationBottom
                                        previousText="Forrige"
                                        nextText="Neste"
                                        className="-striped -highlight success-pagination"
                                        columns={[
                                            { Header: "Selskap", accessor: "companyName", filterable: false },
                                            { Header: "Antall jobber", accessor: "numberOfBookings", filterable: false, headerStyle: { textAlign: 'left' } }
                                        ]}
                                        getTrProps={(state, rowInfo) => ({
                                            onClick: () => this.handleRowClick(rowInfo)
                                        })}
                                        SubComponent={row => (
                                            <div style={{ padding: "20px" }}>
                                                <ReactTable
                                                    minRows={1}
                                                    data={bookingsSubArray}
                                                    columns={[
                                                        { Header: "JobId", accessor: "jobId", width: 80, filterable: false },
                                                        { Header: "Selskap", accessor: "companyName", width: 150, filterable: false, headerStyle: { textAlign: 'left' } },
                                                        { Header: "Kilde", accessor: "source", width: 150, filterable: false },
                                                        { Header: "Jobbtype", accessor: "jobType", filterable: false },
                                                        { Header: "Dato", accessor: "date", filterable: false, width: 150 },
                                                        { Header: "Tid", accessor: "time", width: 90, filterable: false, headerStyle: { textAlign: 'left' } }
                                                    ]}
                                                    showPagination={true}
                                                />
                                            </div>
                                        )}
                                    />
                                </CardBody>
                            </LoadingOverlay>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default CustomerServiceKPI;
